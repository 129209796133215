@import 'Config';
@import '../libs/alumican.css/Function';





$header-margin-top: 30px;
$header-margin-side: 30px;
$header-height: 50px;





/* ---------- logo ---------- */

#header-logo {
	@include fixed-tl(0, 0, $z-header-logo);
	display: flex;
	align-items: center;

	padding-top: $header-margin-top;
	padding-left: $header-margin-side;
	box-sizing: border-box;
	width: 100%;
	justify-content: center;
	height: $header-height;
	@include lt-mobile {
		padding-top: 0;
		padding-left: 10px;
	}
	.logo {
		@include lt-mobile {
			width: 120px;
		}
		@include gt-desktop {
			width: 240px;
			padding-left: 0;
		}

	}

	.description {
		@include text-style-logo-description();
		margin-left: 24px;
	}
}


/* ---------- navigation bar ---------- */

#navigation-bar {
	@include fixed-tr(0, 0, $z-navigation-bar);
	width: 100%;
	
	box-sizing: border-box;
	//background-color: rgba(0, 0, 0, 0.5);
	// border-bottom: 0.1mm solid rgba(0, 0, 0, .6);
	@include lt-mobile {
		// display: none !important;
		height:  $header-height;
		padding-top: 15px;
		padding-right: 60px;
	}
	@include gt-desktop {
		height: $header-margin-top + $header-height;
		padding-top: $header-margin-top;
			padding-right: $header-margin-side;
			.desktop{
				position: fixed;
				right: 5%;
		.support-contents{
			#openModal{
				display: flex;
				img{
					right: 5%;
					top: -2px;
					text-align: right;
					display: inline-block;
					position: relative;
				}
			}
		}
	}
			}
	// .fixed{
	// 	background-color: rgba(255, 255, 255, 1);
	// }
	.content {
		
		display: flex;
		align-items: center;
		justify-content: flex-end;

		a {
			text-decoration: none;
			
		}

		.menu-group {
			@include not-first {
				margin-left: 32px;
			}
			
			@include gt-desktop {
				margin-right: 150px;
			}
			a{
				display: flex;
			img{
			@include lt-mobile {
				position: relative;
				top: -4px;
			}
		}
		}


			ul {
				display: flex;
			}

			&.section {
				li {
					@include not-first {
						margin-left: 24px;
					}
				}
				a {
					@include text-style-header-link();
				}
			}

			&.language {
				li {
					@include not-first {
						margin-left: 16px;
					}
				}
				a {
					@include text-style-header-language();
				}

				.item {
					@mixin check-active($lang) {
						&.#{$lang} {
							@include root-class('lang-' + $lang) {
								text-decoration: underline;
							}
						}
					}
					@include check-active('ja');
					@include check-active('en');
				}
			}
		}
	}
	
}





/* ---------- drawer ---------- */

$padding: 8px;

#drawer{
.drawer-foreground{
	background-color: #333333;
	opacity: 0.98;
}
}

#drawer-open-button {
	@include fixed-tr($padding, $padding, $z-drawer-open-button);
	@include gt-desktop {
		// display: none;
		right: 100%;
		left: 5%;
		top: 15px;
	}
	@include lt-mobile {
		right: 0;
		top: 0;
		// visibility: hidden;
		// opacity: 0;
	}
}

#drawer-content {
	// display: none;

	padding: $padding;
	box-sizing: border-box;

	a {
		text-decoration: none;
	}

	.drawer-close-button {
		margin-bottom: 32px;
		img{
		@include lt-mobile {
		width: 13%;
		margin-left: -85%;
	}
}
	}

	.drawer-menu-group {
		margin-left: 8px;
		.item{
			a{
			color:white;
			img{
				position: absolute;
				display: inline-block;
				right: 20px;
				fill: white;
				margin-top: -4px;
			}
		}
		padding-bottom: 20px;
		border-bottom: 0.1px solid #414141;
		}
		@include not-first {
			//margin-top: 32px;
			margin-top: 24px;
		}

		li {
			@include not-first {
				margin-top: 20px;
			}
		}

		&.section {
			a {
				@include text-style-header-link();
			}
		}

		&.language {
			a {
				@include text-style-header-language();
			}
		}
	}
}
