$z-navigation-bar: 1000;
$z-header-logo: 2000;
$z-drawer-open-button: 3000;
$z-global-navigation: 4000;

$bp-mobile-tablet: 960px;

$line-height-title: 180%;
$line-height-default: 220%;
$line-height-narrow: 150%;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$section-margin: 120px;

$line-height-L:220%;



@mixin text-style-section-title($text-align: left) {
	@include gt-tablet{
		font-size: 40px;
		font-weight: $font-weight-light;
		// line-height: 180%;
		line-height: $line-height-L;
	}
	@include lt-tablet{
		font-size: 32px;
		font-weight: $font-weight-regular;
		line-height: 180%;
	}
	text-align: $text-align;
}

@mixin text-style-section-subtitle($text-align: left) {
	font-size: 24px;
	font-weight: $font-weight-regular;
	line-height: 180%;
	text-align: $text-align;
}

@mixin text-style-list-title($text-align: left) {
	font-size: 16px;
	font-weight: $font-weight-medium;
	line-height: 180%;
	text-align: $text-align;
}

@mixin text-style-body($text-align: left) {
	font-size: 16px;
	font-weight: $font-weight-light;
	line-height: $line-height-L;
	text-align: $text-align;
	color: #ccc;
}

@mixin text-style-header-link() {
	font-size: 14px;
	font-weight: $font-weight-medium;
	line-height: 100%;
	text-align: right;
}

@mixin text-style-header-description() {
	font-size: 14px;
	font-weight: $font-weight-regular;
	line-height: 100%;
	text-align: left;
}

@mixin text-style-header-language() {
	font-size: 14px;
	font-weight: $font-weight-regular;
	line-height: 100%;
	text-align: right;
}

@mixin text-style-footer($text-align: left) {
	font-size: 12px;
	font-weight: $font-weight-bold;
	line-height: 100%;
	text-align: $text-align;
}

@mixin text-style-user-voice-list-title($text-align: left) {
	font-size: 20px;
	font-weight: $font-weight-medium;
	line-height: 180%;
	text-align: $text-align;
}

@mixin text-style-note($text-align: left) {
	font-size: 14px;
	font-weight: $font-weight-light;
	line-height: 180%;
	text-align: $text-align;
	color: #999;
}

@mixin text-style-logo-description() {
	font-size: 12px;
	font-weight: $font-weight-regular;
	line-height: 100%;
	text-align: left;
}
