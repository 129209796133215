@import 'Config';
@import '../libs/alumican.css/Function';


/* ---------- init ---------- */

@include init-safe-area;
@include init-font-family-device-sans-serif;
@include init-root-rem;
@include init-no-interaction-effect;

$margin-SS:12px;
$margin-S:12px;
$margin-M:40px;

$pageSidePadding:20%;


$font-size-title:40px;
$font-size-subtitle:20px;


/* ---------- base layout ---------- */

body {
	font-family:
		//"Helvetica Neue", Helvetica, Arial,
		'Noto Sans JP',
		//Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3",
		//Roboto, "Droid Sans",
		//YuGothic, "游ゴシック",
		//Meiryo, "メイリオ",
		//Verdana,
		//"ＭＳ Ｐゴシック",
		sans-serif;

	font-size: 16px;
	line-height: 0;
	color: #020202;
	font-weight: $font-weight-light;
	text-align: center;

	background-color: #FFF;

	//@include disable-text;
}

ul {
	list-style-type: none;
}





/* ---------- responsive ---------- */

.desktop {
	@include lt-mobile {
		display: none;
	}
}

.mobile {
	@include gt-tablet {
		display: none;
	}
}



.js-modal-btn {
	@include lt-mobile {
		margin-top: 50px;
	}
}

/* ---------- text ---------- */

a {
	color: #000;
	text-decoration: none;

	// hover
	opacity: 1;
	transition: 0.6s;

	@include hover-action {
		opacity: 0.5;
		transition: 0.2s;
	}
}

strong {
	font-weight: bold;
}





/* ---------- button ---------- */

.strong-button {
	@include box-button(280px, 48px, 16px, 0, 216px, 24px);
	background-color: #fff;
	border-radius: 24px;
	color: #000;
	font-size: 16px;
	font-weight: $font-weight-bold;
}

#info-buy {
	position: fixed;
	z-index: 10000;
	bottom: 10px;
	filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, .3));

	@include lt-mobile {
		right: 10px;

		img {
			width: 100px;
		}
	}

	@include gt-desktop {
		right: 20px;

		img {
			width: 120px;
		}
	}
}



/* ---------- card ---------- */

.logo-container {
	background-color: #ccc;
	border-radius: 10px;

	padding: 12px; //24px;
	box-sizing: border-box;

	$grid-gap: 24px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	.logo {
		margin: 12px;
	}

	//display: grid;
	//grid-template-columns: repeat(auto-fill, minmax(144px, 1fr));
	//grid-column-gap: 24px;
	//grid-row-gap:24px;
	//justify-content: space-between;
}



.slick-slide{
	img{
		width: 100%;
	}
}

/* ---------- footer ---------- */

#footer {
	margin-bottom: 40px;


	.link-container {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;

		.link {
			@include text-style-footer(center);
			margin: 0 16px;
		}
	}

	.copyright {
		@include text-style-footer(center);
		margin-top: 64px;
	}

	@include mobile {
		.link-container {
			.link {
				width: 40%;
				margin-top: 5%;
			}
		}
	}
}



#flyer {
	.desktop {


		h2 {
			margin-top: 10px;
			font-size: $font-size-subtitle;
			font-weight: 700;
			letter-spacing: 2px;
		}

		img {


			width: 100%;
			height: 100%;

		}
	}

	// 	.mobile{
	width: 90%;

	@include lt-mobile {
		// right: 10px;
		margin: 5% auto 0;
	}

	// }
	max-width: none;

	.flyer-list {

		// height: 100%;
		.thumbnail-list img {
			width: 60%;
			height: auto;
			margin-top: 10%;
			margin-bottom: 30%;

			display: inline-block;
			//   padding:5px 10px;
			color: #FFF;
			text-decoration: none;
			//   background: skyblue;
			box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);


		}




	}


}



/* ---------- section base ---------- */

section {
	.section-title {
		@include text-style-section-title(center);
	}

	.section-subtitle {
		@include text-style-section-subtitle(center);
	}

	.section-description {
		@include text-style-body(center);
		margin-top: $margin-S;
	}

	@include desktop {
		#id {
			padding-top: 0;
		}

		max-width: 1000px;
		padding: 5% 2.5% 0;
		margin: 0 auto;
	}

	@include mobile {
		// padding: 0 24px;
		box-sizing: border-box;
	}
}





/* ---------- top section ---------- */

#top {
	.desktop {

		// margin-bottom: $section-margin;

		// display: grid;
		.top-area {
			height: 100%;
			padding: 80px 0;
			display: grid;
			grid-template-columns: 5% 90% 5%;
			grid-template-rows: 90%;

			.thumbnail {
				grid-column: 2 / 3;
				grid-row: 1;
				height: 90%;
				width: 100%;
				top: 5%;
				display: inline-block;
				// justify-content: center;
				margin: 0 auto;
				padding: 0 0 0;

				// margin: auto;
				video {
					width: 100%;
					height: auto;
				}

				img {
					width: 100%;
					height: auto;
				}
			}
			.js-modal-btn {
			}

			.subtitle {
				grid-column: 1 / 2;
				grid-row: 1;
				margin-top: 0px;
				height: 90%;
				font-size: 12px;
				font-feature-settings: "palt";
				position: relative;
				display: inline-block;
				width: 50%;
				// left: 2.0%;
				// top: 0%;
				writing-mode: vertical-rl;
			}

			.scroll {
				font-size: 12px;
				grid-column: 3 / 4;
				grid-row: 1;
				height: 65%;
				width: 50%;
				// top: 10%;
				// margin-right: 2%;
				position: relative;
				display: inline-block;

				a {
					display: inline-block;
					position: absolute;
					//   right: 40px;
					bottom: 0;
					z-index: 2;
					padding: 10px 10px 110px;
					overflow: hidden;
					color: #000;
					font-size: 8px;
					font-family: 'Josefin Sans', sans-serif;
					line-height: 1;
					letter-spacing: .2em;
					text-transform: uppercase;
					text-decoration: none;
					writing-mode: vertical-lr;
				}

				a::after {
					content: '';
					position: absolute;
					bottom: 40px;
					left: 50%;
					width: 1px;
					height: 60px;
					background: #000;
				}

				a::after {
					animation: sdl 1.5s cubic-bezier(1, 0, 0, 1) infinite;
				}

				@keyframes sdl {
					0% {
						transform: scale(1, 0);
						transform-origin: 0 0;
					}

					50% {
						transform: scale(1, 1);
						transform-origin: 0 0;
					}

					50.1% {
						transform: scale(1, 1);
						transform-origin: 0 100%;
					}

					100% {
						transform: scale(1, 0);
						transform-origin: 0 100%;
					}
				}
			}

		}

		.text-area {
			display: flex;
			color: black;
			margin: 0 auto;
			width: 80%;
			margin-top: 5%;
			margin-bottom: 5%;
			line-height: 50px;
			font-feature-settings: "palt";
			justify-content: space-between;
			align-items: center;

			h1 {
				width: 50%;
				font-size: 30px;
				// color: #8fc31f;
				// font-size: $font-size-title;
			}

			h2 {
				width: 50%;
				margin-top: 10px;
				font-size: 15px;
				// font-size: $font-size-subtitle;
				font-weight: 100;
				letter-spacing: 2px;
			}
		}

	}

	.mobile {
		button {
			width: 100%;
			border: none;
			background-color: transparent;
			padding: 0;

			.thumbnail-li {
				img {
					background-color: #000;
					width: 100%;
					height: auto;
					// padding-top: 10%;
					// padding-bottom: 10%;
				}
			}
		}
	}
}





/* ---------- introduction section ---------- */

#lineup {
	padding-top: 0%;
	max-width: none;
	width: 90%;

	@include lt-mobile {
		width: 100%;
		margin: auto;
	}

	.desktop {
		// padding-bottom: 4%;
		width: 100%;

		.section-title {
			font-weight: bold;

			h2 {
				// font-size: 10px;
				font-size: $font-size-subtitle;
			}
		}

		.section-subtitle {
			font-weight: bold;

			// font-weight: bold;
			h2 {
				font-size: 14px;
				// font-size: $font-size-subtitle;
			}
		}

		h2 {
			// font-size: 10px;
			font-size: $font-size-subtitle;
		}

		margin: 4% 0 0;


		img {
			margin-top: 1%;
			width: 100%;
		}

		.lineup-box {

			// display: flex;
			// flex-wrap: wrap;
			// align-content: center;
			.lineup-contents {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin: 0% 0 12%;

				h2 {

					width: 35%;
				}

				a {
					width: 65%;
				}

			}
		}
	}
}

.mobile {
	.lineup-contents {
		width: 99%;
		margin: 2% auto;

		img {
			width: 100%;
			// margin: 0 auto;
		}
	}
}


#other {
	// padding-top: 0%;
	// padding-bottom: 2%;
	padding: 0 0 2%;

	.desktop {
		width: 100%;
		// display: inline-block;
		margin: 0% 50% 0%;

		h2 {
			font-size: $font-size-subtitle;
		}

		img {
			margin-top: 1%;
			width: 50%;
		}


		padding:0% 0 5%;
	}

	.mobile {
		margin: 4% 2.5%;

		&.pdf {
			a {
				img {
					width: 100%;
				}
			}
		}

		.support {
			display: flex;
			justify-content: space-between;

			.support-contents {
				width: 48%;
				// height: 150px;
				border: 0.1px solid #cccccc;
				padding: 5% 0;

				h2 {
					font-size: 13px;
				}

				img {
					opacity: 0.5;
					fill: #cccccc;
				}
			}

			#question {
				img {
					width: 30%;
				}
			}

			#contact {
				img {
					width: 30%;
				}
			}
		}

	}

	.desktop {
		margin: 0% 0% 0%;

		// &.pdf{
		// 	a{
		// 		img{
		// 			width: 100%;
		// 		}
		// 	}
		// }

		.support {
			position: fixed;
			// justify-content: space-between;
			top: 2.5%;
			right: 5%;
			z-index: 999999;

			.support-contents {
				#openModal {

					display: flex;
					flex-wrap: nowrap;
					width: 100%;
					// height: 150px;
					// border: 0.1px solid #cccccc;
					// padding: 5% 0;

					h2 {
						font-size: 13px;
						width: 80%;
					}

					img {
						width: 20%;
						opacity: 0.5;
						fill: #cccccc;
					}
				}
			}

			// #question {
			// 	img {
			// 		width: 30%;
			// 	}
			// }

			// #contact {
			// 	img {
			// 		width: 30%;
			// 	}
			// }
		}

	}
}